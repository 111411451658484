.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-title {
  height: 20vmin;
  pointer-events: none;
  margin-bottom:2em;
}

.App-header {
  background-image: url("../img/logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-color:#0D111A;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #e315e3;


}
